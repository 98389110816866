/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

import { Viewer } from '@/src/entities/auth-user/ui/Viewer'
import { AUTH_QUERY_NAME } from '@/src/features/auth/config'
import { LogOut } from '@/src/features/auth/ui/LogOut'
import { Button } from '@/src/shared/ui/Button'
import { useAuthModalContext } from '@/src/widgets/Auth/context'

const Auth = () => {
  const { onAuthModalChange } = useAuthModalContext()
  const searchParams = useSearchParams()

  const hasAuthQuery = searchParams.has(AUTH_QUERY_NAME)

  useEffect(() => {
    onAuthModalChange(hasAuthQuery)
  }, [hasAuthQuery])

  return (
    <Viewer
      slotToLogin={
        <Button
          onClick={onAuthModalChange}
          variant="fourth"
          squared
          size="s"
          iconName="user-icon"
        />
      }
      slotLogoutButton={<LogOut />}
    />
  )
}

export default Auth

import { apiClientPlaytest } from '@/src/shared/api'
import { IFormDto } from '@/src/shared/api/dto'
import { mapForm } from '@/src/shared/api/mapper'
import { IForm } from '@/src/shared/model'

import { mapLogin, mapLogout } from '../api/mapper'
import { ILogin, ILogout } from '../model'
import { ILoginDto, ILogoutDto } from './dto'

export const registration = async <TFormBody>(body: TFormBody): Promise<IForm> => {
  const result = await apiClientPlaytest.post<IFormDto>('auth/registration', body)

  return mapForm(result)
}

export const restore = async <TFormBody>(body: TFormBody): Promise<IForm> => {
  const result = await apiClientPlaytest.post<IFormDto>('auth/password/reset', body)

  return mapForm(result)
}

export const login = async <TFormBody>(body: TFormBody): Promise<ILogin> => {
  const result = await apiClientPlaytest.post<ILoginDto>('auth/login', body)

  return mapLogin(result)
}

export const logout = async (): Promise<ILogout> => {
  const result = await apiClientPlaytest.post<ILogoutDto>('auth/logout')

  return mapLogout(result)
}
